import { StepCategoryId } from '~/consts/assistant-steps';
import { AssistantType } from '~/consts/assistant-types';

export const getCategoryId = (assistantType: AssistantType): string => {
  if (assistantType === AssistantType.E_MOBILITY) {
    return StepCategoryId.EMOBILITY.toString();
  }
  if (assistantType === AssistantType.SOLAR) {
    return StepCategoryId.SOLAR.toString();
  }
  if (assistantType === AssistantType.HEATING) {
    return StepCategoryId.HEATING.toString();
  }
  return '';
};

export const getAssistantRootFrom = (
  categoryId: string,
): AssistantType | null => {
  if (categoryId.startsWith('emobility')) {
    return AssistantType.E_MOBILITY;
  }
  if (categoryId.startsWith('solar')) {
    return AssistantType.SOLAR;
  }
  if (categoryId.startsWith('heating')) {
    return AssistantType.HEATING;
  }
  return null;
};

export const getAssistantTypeFrom = (id: string): AssistantType | null => {
  if (id === AssistantType.E_MOBILITY.toString()) {
    return AssistantType.E_MOBILITY;
  }
  if (id === AssistantType.SOLAR.toString()) {
    return AssistantType.SOLAR;
  }
  if (id === AssistantType.HEATING.toString()) {
    return AssistantType.HEATING;
  }
  return null;
};
