import component from './SolarRoofShape.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { FormFieldType } from '@/consts/input-types';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';
import i18n from '@/plugins/i18n';

export interface SolarRoofShapeStepContext extends AssistantStepContext {
  roofShape: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    {
      component: {
        columns: 3,
        hideCheckbox: true,
        options: [
          {
            value: 'flat-roof',
            image: {
              file: 'img/icon/flat-roof.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'pitched-roof',
            image: {
              file: 'img/icon/pitched-roof.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'peaked-roof',
            image: {
              file: 'img/icon/peaked-roof.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
        outputFormatter: (data) => ({
          formattedLabel: i18n.t(
            'solarRoofShape.formFields.roofShape.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue,
        }),
      } as FormMultipleChoiceComponentConfig,
      name: 'roofShape',
      required: true,
      type: FormFieldType.MultipleChoice,
      groupId: FormFieldGroupId.OBJECT,
    },
  ],
  id: StepId.SOLAR_ROOF_SHAPE,
  categoryId: StepCategoryId.SOLAR,
};

export default config;
