import { AssistantContext } from '~/steps/steps';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { PersistedState } from '@/interfaces/persisted-state';

const siteName = process.env.VUE_APP_SITE_NAME;
const ASSISTANT_STATE_LOCAL_STORAGE_KEY = siteName
  ? `gc-${siteName.toLowerCase()}-state`
  : 'gc-base-assistant-state';

export const loadStateFromLocalStorage = (): PersistedState | null => {
  if (typeof localStorage !== 'undefined') {
    const savedState = localStorage.getItem(ASSISTANT_STATE_LOCAL_STORAGE_KEY);
    return savedState
      ? ({ ...JSON.parse(savedState) } as PersistedState)
      : null;
  }
  return null;
};

export const saveStateToLocalStorage = (
  context: AssistantContext,
  step: string | StepId,
  category?: string | StepCategoryId,
): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(
      ASSISTANT_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify({
        context,
        createdAt: new Date().toISOString(),
        step,
        category,
      } as PersistedState),
    );
  }
};

export const clearStateFromLocalStorage = (): void => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(ASSISTANT_STATE_LOCAL_STORAGE_KEY);
  }
};
