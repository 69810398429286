import component from './SolarInterest.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';

export interface SolarInterestStepContext extends AssistantStepContext {}

const config: StepDefinition = {
  component,
  // isInitial: true,
  hideSkipMessage: () => true,
  fields: [],
  id: StepId.SOLAR_INTEREST,
  categoryId: StepCategoryId.ROOT,
};

export default config;
