import component from './EmobilityInterest.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { FormFieldType } from '@/consts/input-types';
import i18n from '@/plugins/i18n';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface EmobilityInterestStepContext extends AssistantStepContext {
  interest: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  component,
  isInitial: true,
  hideSkipMessage: () => true,
  fields: [
    {
      component: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'buy',
            image: {
              file: 'img/icon/buy-wallbox.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'check',
            image: {
              file: 'img/icon/install-check.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      },
      name: 'interest',
      required: true,
      type: FormFieldType.MultipleChoice,
      groupId: FormFieldGroupId.PRODUCT,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'emobilityInterest.formFields.interest.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue,
        };
      },
    },
  ],
  id: StepId.EMOBILITY_INTEREST,
  categoryId: StepCategoryId.ROOT,
};

export default config;
