import heatingType, { HeatingTypeStepContext } from '~/steps/heating/type';
import heatingUsage, { HeatingUsageStepContext } from '~/steps/heating/usage';
import heatingDistribution, {
  HeatingDistributionStepContext,
} from '~/steps/heating/distribution';
import heatingConstructionYear, {
  HeatingConstructionYearStepContext,
} from '~/steps/heating/construction-year';
import heatingBuildingType, {
  HeatingBuildingTypeStepContext,
} from '~/steps/heating/building-type';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [
  heatingType,
  heatingUsage,
  heatingDistribution,
  heatingConstructionYear,
  heatingBuildingType,
];

// Add the imported context interface for the steps
export interface HeatingAssistantContext {
  heatingType: HeatingTypeStepContext;
  heatingUsage: HeatingUsageStepContext;
  heatingDistribution: HeatingDistributionStepContext;
  heatingConstructionYear: HeatingConstructionYearStepContext;
  heatingBuildingType: HeatingBuildingTypeStepContext;
}

export default steps;
