





















































































































































































































































































































import { faCheck, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { defineComponent } from '@vue/composition-api';
import GcButton from '@/components/primitives/GcButton.vue';
import GcCard from '@/components/primitives/GcCard.vue';
import GcCardActions from '@/components/primitives/GcCardActions.vue';
import GcCardText from '@/components/primitives/GcCardText.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcDialog from '@/components/primitives/GcDialog.vue';
import GcDivider from '@/components/primitives/GcDivider.vue';
import GcImage from '@/components/primitives/GcImage.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import { GcSwProduct } from '@/lib/greenportal/load-products';
import WallboxDetailsItem from '~/components/wallbox-details-item/WallboxDetailsItem.vue';
import { useWallboxModal } from '~/components/wallbox-modal/wallbox-modal';

export default defineComponent({
  components: {
    WallboxDetailsItem,
    GcButton,
    GcCard,
    GcCardActions,
    GcCardText,
    GcCol,
    GcDialog,
    GcDivider,
    GcImage,
    GcRow,
  },
  setup: () => {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    const productUrl = (wallbox: GcSwProduct) =>
      wallbox.cover?.media?.url
        ? wallbox.cover?.media?.url
        : `${baseUrl}/img/image-placeholder.svg`;

    return {
      baseUrl,
      faPlus,
      faTimes,
      faCheck,
      productUrl,
      ...useWallboxModal(),
    };
  },
});
