import Vue from 'vue';
import AssistantSidebar from './AssistantSidebar.vue';

const AssistantSidebarPlugin = {
  install: (app) => {
    app.component('assistant-sidebar', AssistantSidebar);
  },
};

Vue.use(AssistantSidebarPlugin);

export default AssistantSidebarPlugin;
