var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar gc-fixed gc-bottom-0 gc-mx-4 sm:gc-mr-0 sm:gc-right-0",class:_vm.open
      ? 'gc-rounded-t-3xl sm:gc-rounded-lt-3xl sm:gc-rounded-r-none is-open sm:gc-w-4/5 xl:gc-w-3/5'
      : 'gc-rounded-t-3xl sm:gc-rounded-l-3xl sm:gc-rounded-r-none sm:gc-bottom-32 gc-h-16 sm:gc-w-16 sm:gc-h-auto'},[_c('header',{staticClass:"gc-flex gc-justify-center gc-items-center gc-bg-primary gc-px-4",class:_vm.open
        ? 'gc-rounded-t-3xl sm:gc-rounded-lt-3xl sm:gc-rounded-r-none sm:gc-place-content-between'
        : 'gc-rounded-t-3xl sm:gc-rounded-l-3xl sm:gc-rounded-r-none sm:gc-flex-col sm:gc-px-0 sm:gc-py-4'},[_c('nav',{staticClass:"gc-w-full gc-max-w-xs gc-divide-y sm:gc-divide-y-0 gc-divide-white gc-divide-opacity-50",class:_vm.open ? 'sm:gc-flex sm:gc-flex-row sm:gc-divide-x sm:gc-py-4' : ''},[_c('div',{staticClass:"gc-flex gc-justify-center gc-items-center gc-px-2 sm:gc-px-0"},[_c('button',{staticClass:"toggle gc-visible sm:gc-invisible sm:gc-w-0",attrs:{"type":"button"},on:{"click":_vm.toggleSidebar}},[_c('font-awesome-icon',{attrs:{"icon":_vm.open ? _vm.faArrowDown : _vm.faArrowUp}})],1),_c('p',{staticClass:"gc-text-white gc-text-nowrap gc-text-3xl gc-m-4 gc-p-0 gc-cursor-pointer md:gc-whitespace-no-wrap",class:_vm.open
              ? 'sm:gc-my-0 sm:gc-mx-0 sm:gc-mx-4 lg:gc-w-64'
              : 'vertical-text',on:{"click":_vm.toggleSidebar}},[_vm._v(" "+_vm._s(_vm.$t('myAssistant'))+" ")])]),_c('ul',{staticClass:"gc-px-2 gc-py-4 sm:gc-py-0 gc-place-content-between"},_vm._l((_vm.assistants),function(assistant){return _c('li',{key:assistant,staticClass:"gc-px-2 sm:gc-px-4 lg:gc-pl-8 lg:gc-pr-0",class:[
            { active: _vm.isActive === assistant },
            { inactive: _vm.isActive !== assistant && _vm.isActive !== null } ],attrs:{"data-category":_vm.getCategoryId(assistant)}},[_c('a',{staticClass:"gc-flex gc-flex-col lg:gc-flex-row gc-justify-center gc-items-center",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleContent(assistant)}}},[_c('i',{staticClass:"gc-rounded-full gc-h-16 gc-w-16 gc-flex gc-items-center gc-justify-center",class:[
                'circle-icon',
                assistant,
                _vm.isActive === assistant
                  ? 'gc-bg-highlight'
                  : 'gc-bg-white gc-bg-opacity-50' ]}),_c('span',{staticClass:"gc-text-sm gc-text-center gc-justify-center gc-uppercase gc-leading-5 gc-pt-2 lg:gc-pl-2",class:[{ 'gc-text-white': _vm.isActive === assistant }],domProps:{"innerHTML":_vm._s(_vm.$t('assistants.' + assistant))}})])])}),0)]),_c('button',{staticClass:"toggle gc-invisible sm:gc-visible",attrs:{"type":"button"},on:{"click":_vm.toggleSidebar}},[_c('font-awesome-icon',{attrs:{"icon":_vm.open ? _vm.faArrowRight : _vm.faArrowLeft}})],1)]),_c('div',[(!_vm.assistantStarted)?_c('restore-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"restore",attrs:{"hasPersistedState":_vm.machine.hasPersistedState.value},on:{"restore":function($event){_vm.restoreState();
        _vm.restoreToggleState();
        _vm.assistantStarted = true;}}}):_vm._e(),_c('section',{staticClass:"gc-h-3/4 gc-overflow-y-auto",class:['content', { show: _vm.open && _vm.isActive }]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open && _vm.isActive),expression:"open && isActive"}],ref:"content",attrs:{"id":_vm.isActive + '-app'}},[_vm._t("default")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }