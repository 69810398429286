























import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { defineComponent } from '@vue/composition-api';
import GcButton from '@/components/primitives/GcButton.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';

export default defineComponent({
  components: {
    GcRow,
    GcCol,
    GcButton,
  },
  props: {
    title: String,
    onPrev: Function,
  },
  setup: (props) => ({
    props,
    faArrowLeft,
  }),
});
