import component from './EmobilityBuy.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';

export interface EmobilityBuyStepContext extends AssistantStepContext {}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [],
  id: StepId.EMOBILITY_BUY,
  categoryId: StepCategoryId.EMOBILITY,
};

export default config;
