











































































































import { defineComponent } from '@vue/composition-api';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { stepComponentProps } from '@/lib/steps/helper';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import { StepId } from '~/consts/assistant-steps';

export default defineComponent({
  components: {
    GcRow,
    GcCol,
    GcButton,
    AssistantStep,
    AssistantStepNavigation,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const jumpToStep = (stepId: StepId) => {
      emit('transition', {
        event: 'JUMP_TO_' + stepId.toUpperCase(),
      });
    };
    const baseUrl = process.env.VUE_APP_BASE_URL;

    return {
      baseUrl,
      jumpToStep,
      StepId,
      faCheck,
      faArrowLeft,
      faArrowRight,
    };
  },
});
