import component from './HeatingDistribution.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { FormFieldType } from '@/consts/input-types';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface HeatingDistributionStepContext extends AssistantStepContext {
  distribution: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    {
      component: {
        columns: 2,
        hideCheckbox: true,
        // disableUnselect: true,
        options: [
          {
            value: 'radiator',
            image: {
              file: 'img/icon/radiator.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'underfloorHeating',
            image: {
              file: 'img/icon/floor-heating.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
        ],
        // singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceComponentConfig,
      name: 'distribution',
      required: true,
      type: FormFieldType.MultipleChoice,
      groupId: FormFieldGroupId.HOUSEHOLD,
    },
  ],
  id: StepId.HEATING_DISTRIBUTION,
  categoryId: StepCategoryId.HEATING,
};

export default config;
