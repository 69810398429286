import emobilityInterest, {
  EmobilityInterestStepContext,
} from '~/steps/emobility/interest';
import emobilityBuy, { EmobilityBuyStepContext } from '~/steps/emobility/buy';
import emobilityCheck, {
  EmobilityCheckStepContext,
} from '~/steps/emobility/check';
import solarInterest, {
  SolarInterestStepContext,
} from '~/steps/solar/interest';
import solarSteps, { SolarAssistantContext } from '~/steps/solar/steps';
import heatingSteps, { HeatingAssistantContext } from '~/steps/heating/steps';
import heatingInterest, {
  HeatingInterestStepContext,
} from '~/steps/heating/interest';
import assistantSubmit, { AssistantSubmitStepContext } from '~/steps/submit';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [
  emobilityInterest,
  emobilityBuy,
  emobilityCheck,
  solarInterest,
  ...solarSteps,
  heatingInterest,
  ...heatingSteps,
  assistantSubmit,
];

// Add the imported context interface for the steps
export interface AssistantContext
  extends SolarAssistantContext,
    HeatingAssistantContext {
  emobilityInterest: EmobilityInterestStepContext;
  emobilityBuy: EmobilityBuyStepContext;
  emobilityCheck: EmobilityCheckStepContext;
  solarInterest: SolarInterestStepContext;
  heatingInterest: HeatingInterestStepContext;
  assistantSubmit: AssistantSubmitStepContext;
}

export default steps;
