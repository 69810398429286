import { StepCategoryId } from '~/consts/assistant-steps';
import {
  FieldOutputFormatterReturn,
  FormattedData,
  FormattedDataSummary,
} from '@/interfaces/step-definition';
import i18n from '@/plugins/i18n';
import { getStepContext } from '@/lib/context';
import { StepTypes } from '@/consts/step-types';
import { getFormattedFieldData } from '@/lib/formatter/context';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

interface FormattedCategoryData extends FormattedData {
  categoryId: StepCategoryId | string;
}

export const getFormattedData = ({
  categoryId,
  assistantContext,
  //   currentStepIndex,
  steps,
  target,
}: FormattedCategoryData): FormattedDataSummary => {
  const summary: FormattedDataSummary = [];
  const groups: Array<FormFieldGroupId> = [];

  steps.map((step) => {
    step.fields?.map((field) => {
      const group = field.groupId as FormFieldGroupId;
      if (group && !groups.includes(group)) {
        groups.push(group);
      }
    });
  });

  const summaryGroups: Array<{
    fields: FieldOutputFormatterReturn[];
    id: string;
    title: string;
  }> = [];

  groups.map((group) => {
    summaryGroups.push({
      fields: [],
      id: group,
      title: i18n.t(`group.${group}`).toString(),
    });
  });

  steps.map((step, stepIndex) => {
    // if (currentStepIndex && currentStepIndex <= stepIndex) {
    //   return;
    // }
    if (step.isDisabled && step.isDisabled(assistantContext)) {
      return;
    }
    if (
      step.type &&
      step.type !== StepTypes.DEFAULT &&
      step.type !== StepTypes.CONTACT_FORM
    ) {
      return;
    }
    const formData = getStepContext(assistantContext, step);

    step.fields?.map((field) => {
      const formattedField = getFormattedFieldData(
        field,
        assistantContext,
        formData.value,
        step,
        target,
      );
      const isFormatted =
        formattedField.formattedLabel != false ||
        formattedField.formattedValue != false;
      const emptyValue =
        formattedField.formattedValue == '' ||
        formattedField.formattedValue == '–' ||
        formattedField.formattedValue === undefined;

      if (isFormatted && !emptyValue && !!field.groupId) {
        summaryGroups.map((group) => {
          if (group.id === field.groupId) {
            group.fields.push(formattedField);
          }
        });
      }
    });
  });

  summary.push({
    categoryId: categoryId as StepCategoryId,
    categoryTitle: i18n.t(`assistantSubmit.${categoryId}.title`) as string,
    steps: summaryGroups.filter((group) => group.fields.length > 0),
  });

  return summary;
};
