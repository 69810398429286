



































































































import { defineComponent } from '@vue/composition-api';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { stepComponentProps } from '@/lib/steps/helper';
import GcForm from '@/components/primitives/GcForm.vue';
import GcButton from '@/components/primitives/GcButton.vue';
import { StepId } from '~/consts/assistant-steps';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import AssistantHeader from '~/components/AssistantHeader.vue';

export default defineComponent({
  components: {
    GcForm,
    GcRow,
    GcCol,
    GcButton,
    AssistantStep,
    AssistantStepNavigation,
    AssistantHeader,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const onPrev = () => {
      emit('transition', {
        event: 'JUMP_TO_' + StepId.EMOBILITY_INTEREST.toUpperCase(),
      });
    };

    const onNext = () => {
      emit('transition', {
        event: 'JUMP_TO_' + StepId.ASSISTANT_SUBMIT.toUpperCase(),
      });
    };
    const baseUrl = process.env.VUE_APP_BASE_URL;

    return {
      baseUrl,
      onPrev,
      onNext,
      StepId,
      faCheck,
      faArrowLeft,
      faArrowRight,
    };
  },
});
