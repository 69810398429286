import component from './HeatingUsage.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { FormFieldType } from '@/consts/input-types';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface HeatingUsageStepContext extends AssistantStepContext {
  currentUse: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    {
      component: {
        columns: 2,
        disableUnselect: true,
        options: [
          {
            value: 'heatHotwater',
            image: {
              file: 'img/icon/space-heating-1.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'spaceHeating',
            image: {
              file: 'img/icon/space-heating-1.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceComponentConfig,
      name: 'currentUse',
      required: true,
      type: FormFieldType.MultipleChoice,
      groupId: FormFieldGroupId.HOUSEHOLD,
    },
  ],
  id: StepId.HEATING_USAGE,
  categoryId: StepCategoryId.HEATING,
};

export default config;
