import component from './SolarRoofDirection.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormSliderComponentConfig,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { buildFormSlider } from '@/lib/forms/builder';
import i18n from '@/plugins/i18n';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface SolarRoofDirectionStepContext extends AssistantStepContext {
  roofDirection: FormSliderComponentConfig;
}

const tickLabels: Array<string> = [];

tickLabels[0] = i18n
  .t('solarRoofDirection.formFields.roofDirection.options.west.label')
  .toString();
tickLabels[1] = i18n
  .t('solarRoofDirection.formFields.roofDirection.options.south.label')
  .toString();
tickLabels[2] = i18n
  .t('solarRoofDirection.formFields.roofDirection.options.east.label')
  .toString();

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    buildFormSlider('roofDirection', {
      component: {
        min: 0,
        max: 2,
        thumbLabel: false,
        tickLabels,
        showTicks: false,
        thumbSize: 50,
      },
      outputFormatter: (data) => ({
        formattedLabel: i18n.t(
          'solarRoofDirection.formFields.roofDirection.formattedLabel',
        ) as string,
        formattedValue: tickLabels[data?.formattedValue],
      }),
      groupId: FormFieldGroupId.OBJECT,
    }),
  ],
  id: StepId.SOLAR_ROOF_DIRECTION,
  categoryId: StepCategoryId.SOLAR,
};

export default config;
