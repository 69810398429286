




























































import { defineComponent } from '@vue/composition-api';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import AssistantStep from '@/components/AssistantStep.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { stepComponentProps } from '@/lib/steps/helper';
import GcButton from '@/components/primitives/GcButton.vue';
import AssistantHeader from '~/components/AssistantHeader.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import { StepId } from '~/consts/assistant-steps';
import WallboxList from '~/components/wallbox-list/WallboxList.vue';

export default defineComponent({
  components: {
    WallboxList,
    GcForm,
    GcRow,
    GcCol,
    GcButton,
    AssistantStep,
    AssistantStepNavigation,
    AssistantHeader,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const onPrev = () => {
      emit('transition', {
        event: 'JUMP_TO_' + StepId.EMOBILITY_INTEREST.toUpperCase(),
      });
    };

    const onNext = () => {
      emit('transition', {
        event: 'JUMP_TO_' + StepId.ASSISTANT_SUBMIT.toUpperCase(),
      });
    };

    return {
      onNext,
      onPrev,
      StepId,
      faArrowLeft,
      faArrowRight,
    };
  },
});
