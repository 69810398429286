import component from './HeatingType.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { FormFieldType } from '@/consts/input-types';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface HeatingTypeStepContext extends AssistantStepContext {
  currentHeatingF: FormMultipleChoiceValueType;
}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    {
      component: {
        columns: 4,
        // disableUnselect: true,
        hideCheckbox: true,
        options: [
          {
            value: 'gas',
            image: {
              file: 'img/icon/gas.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'oil',
            image: {
              file: 'img/icon/oil.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'electricity',
            image: {
              file: 'img/icon/electricity.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
          {
            value: 'other',
            image: {
              file: 'img/icon/other.svg',
              options: {
                height: 35,
                width: 35,
              },
            },
          },
        ],
        singleAnswer: true,
        verticalOptionLayout: true,
      } as FormMultipleChoiceComponentConfig,
      name: 'currentHeating',
      required: true,
      type: FormFieldType.MultipleChoice,
      groupId: FormFieldGroupId.HEATER,
    },
  ],
  id: StepId.HEATING_TYPE,
  categoryId: StepCategoryId.HEATING,
};

export default config;
