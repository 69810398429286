/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  general: {
    button: {
      back: 'Zurück',
    },
  },
  myAssistant: 'Mein E-Assistant',
  assistants: {
    emobilityInterest: 'Elektro-<br>Mobilität',
    solarInterest: 'Solar',
    heatingInterest: 'Wärme',
  },
  wallboxes: {
    title: 'Wallboxen',
    step: {
      label: 'Wallboxen',
    },
    description:
      'Vielen Dank für Ihre Anfrage. Die für Sie am besten geeignete Wallbox werden wir gemeinsam mit Ihnen ermitteln. Hier können Sie sich jedoch schon einmal einen Überblick über mögliche Wallboxmodelle verschaffen.',
    loadingError: 'Produkte konnten nicht geladen werden.',
    loadingMessage: 'Produkte werden geladen …',
    noProductsFound:
      'Leider gibt es keine Produkte, die Ihren Kriterien entsprechen.',
    moreInformation: 'Weitere Informationen',
    modal: {
      button: {
        close: 'Schließen',
      },
      details: {
        general: {
          label: 'Allgemein',
          chargingPowerConfigurable: 'Ladeleistung konfigurierbar',
          electricCurrentConfigurable: 'Stromstärke konfigurierbar',
        },
      },
      extras: {
        label: 'Extras',
      },
      chargingPoints: {
        label: 'Ladepunkte',
      },
      dimensions: {
        label: 'Produktmaße',
        width: 'Breite',
        height: 'Höhe',
        length: 'Länge',
        weight: 'Gewicht',
      },
    },
  },
  group: {
    product: 'Produkte',
    heater: 'Heizung',
    power: 'Strom',
    household: 'Haushalt',
    object: 'Objekt',
    other: 'Sonstiges',
    contact: 'Kontakt',
  },
  privacy:
    'Wir verarbeiten Ihre Kontaktangaben auf Ihre Anfrage hin zum Zwecke der Erstellung eines kostenlosen und unverbindlichen Angebots. Weitere Informationen finden Sie in unserer <a href="https://www.stawag.de/datenschutz/" target="_blank">Datenschutzerklärung</a>, deren Inhalt Sie zur Kenntnis genommen haben.',
  sentSuccessfully: {
    title: 'Vielen Dank!',
    text: 'Wir melden uns baldmöglichst. Sie erhalten eine E-Mail an die von Ihnen angegebene E-Mail-Adresse',
  },
  emobilityInterest: {
    title: 'Wofür interessieren Sie sich?',
    step: {
      label: 'Interesse',
    },
    formFields: {
      interest: {
        formattedLabel: 'Interesse',
        options: {
          buy: {
            label: 'Neue Wallbox kaufen',
            description: '(mit und ohne Installation)',
          },
          check: {
            label: 'Installationscheck für Wallboxen',
            description: '(Nur im Postleitzahlengebiet 52xxx)',
          },
        },
      },
    },
  },
  emobilityBuy: {
    title: 'Neue Wallbox kaufen',
    button: {
      allProducts: 'Zu allen Produkten',
    },
    products: {
      desc: 'Sie sind auf der Suche nach einer passenden Wallbox? Werden Sie in unserem Produktsortiment fündig.',
      top: 'Top Produkte',
      wallboxHint: 'Alle Wallboxen sind mit oder ohne Installation erhältlich.',
      button: {
        allProducts: 'Zu allen Produkten',
      },
    },
    consultation: {
      box: {
        header: 'Sie benötigen Hilfe bei Ihrer Auswahl?',
        text: 'Gerne empfehlen wir Ihnen in einer persönlichen Beratung das für Sie am besten geeignete Produkt.',
        button: 'Zur Beratung',
      },
    },
  },
  emobilityCheck: {
    title: 'Installationscheck für Wallboxen',
    desc: 'Lassen Sie von unserem Fachpartner prüfen, ob Ihre Hausinstallation fit für eine Wallbox ist. Jetzt ganz einfach Angebot und Termin anfordern.',
    benefits: {
      benefit1: 'Technische Voraussetzungen klären',
      benefit2: '<strong>Sicherheit</strong>',
      benefit3: '<strong>Vor-Ort-Termin</strong>',
    },
    price: {
      from: 'ab',
      value: '129,- €*',
      tax: '* inkl. 0% Umsatzsteuer<br />Angebotspreis kann abweichen',
    },
    taxHint:
      'Gemäß Jahressteuergesetz 2022 gilt: Lieferung, Installation von Modulen, Wechselrichter und Stromspeicher mit Umsatzsteuer 0%',
    consultation: 'Ihr individuelles Angebot',
  },
  solarInterest: {
    title: 'Sie interessieren sich für Photovoltaik?',
    desc: 'Wir helfen Ihnen gerne. Fordern Sie jetzt ein kostenloses Angebot für eine Photovoltaikanlage im Ratenkauf an!',
    benefits: {
      benefit1: 'Eigene PV-Anlage im <strong>Ratenkaufmodell</strong>',
      benefit2: 'Strom produzieren & <strong>Stromkosten senken</strong>',
      benefit3: '<strong>Flexible Laufzeiten</strong> von 10-15 Jahren',
    },
    price: {
      from: 'monatlich ab',
      value: '555,- €*',
      tax: '* inkl. 0% Umsatzsteuer<br />Angebotspreis kann abweichen',
    },
    taxHint:
      'Gemäß Jahressteuergesetz 2022 gilt: Lieferung, Installation von Modulen, Wechselrichter und Stromspeicher mit Umsatzsteuer 0%',
    consultation: 'Ihr individuelles Angebot',
  },
  solarRoofShape: {
    title: 'Angaben zu Ihrer Photovoitalk-Anlage',
    step: {
      label: 'Dachform',
    },
    formFields: {
      roofShape: {
        label: 'Dachform Ihres Hauses',
        formattedLabel: 'Dachform',
        helper:
          'Der Neigungswinkel des Daches beeinflusst die Menge an Strom, die Ihre Photovoltaik-Anlage produziert.',
        options: {
          'flat-roof': { label: 'Flachdach' },
          'pitched-roof': { label: 'Schrägdach' },
          'peaked-roof': { label: 'Spitzdach' },
        },
      },
    },
  },
  solarRoofDirection: {
    title: 'Angaben zu Ihrer Photovoitalk-Anlage',
    step: {
      label: 'Dachausrichtung',
    },
    formFields: {
      roofDirection: {
        label: 'Ausrichtung Ihres Daches',
        formattedLabel: 'Dachausrichtung',
        helper:
          'Die Dachausrichtung beeinflusst die Anzahl an Sonnenstunden, denen Ihre Photovoltaik-Anlage ausgesetzt ist.',
        options: {
          west: { label: 'West' },
          south: { label: 'Süd' },
          east: { label: 'Ost' },
        },
      },
    },
  },
  solarPowerUsage: {
    title: 'Angaben zu Ihrer Photovoitalk-Anlage',
    step: {
      label: 'Stromverbrauch',
    },
    formFields: {
      personCount: {
        label: 'Anzahl Personen',
        helper:
          'Die Anzahl der in Ihrem Haushalt lebenden Personen beeinflusst die Strommenge, die Sie durchschnittlich verbrauchen.',
        options: {
          1: { label: '1' },
          2: { label: '2' },
          3: { label: '3' },
          4: { label: '4' },
          '5+': { label: '5+' },
        },
      },
      powerUsage: {
        label: 'oder genauer Stromverbrauch:',
        formattedLabel: 'Stromverbrauch',
        suffix: 'kWh',
      },
    },
  },
  heatingInterest: {
    title: 'Sie interessieren sich für eine neue Heizung?',
    desc: 'Passend zu Ihren Anforderungen und den Gegebenheiten in Ihrem Objekt machen wir Ihnen gerne Angebote für eine neue Heizung. Jetzt unverbindliches Angebot anfordern!',
    benefits: {
      benefit1: 'Lorem ipsum <strong>Gasanschluss</strong>',
      benefit2: 'Wärme produzieren mit <strong>Fernwärme</strong>',
      benefit3: '<strong>Wärmepumpe</strong> für 10-15 Jahre',
    },
    price: {
      from: 'ab',
      value: '555,- €*',
      tax: '* inkl. 0% Umsatzsteuer<br />Angebotspreis kann abweichen',
    },
    taxHint:
      'Gemäß Jahressteuergesetz 2022 gilt: Lieferung, Installation von Modulen, Wechselrichter und Stromspeicher mit Umsatzsteuer 0%',
    consultation: 'Ihr individuelles Angebot',
  },
  heatingType: {
    label: 'Angaben zu Ihrer Heizung',
    step: {
      label: 'Heizart',
    },
    formFields: {
      currentHeating: {
        label: 'Womit heizen Sie aktuell?',
        options: {
          electricity: {
            label: 'Strom',
          },
          gas: {
            label: 'Gas',
          },
          oil: {
            label: 'Öl',
          },
          other: {
            label: 'Sonstiges',
          },
        },
        title: 'Aktueller Brennstoff',
      },
    },
  },
  heatingUsage: {
    label: 'Angaben zu Ihrer Heizung',
    step: {
      label: 'Nutzung',
    },
    formFields: {
      currentUse: {
        label: 'Wie wird die Heizung aktuell genutzt?',
        helper: 'lorem ipsum text',
        options: {
          heatHotwater: {
            label: 'Raumwärme und Warmwasser',
          },
          spaceHeating: {
            label: 'Nur Raumwärme',
          },
        },
        title: 'Nutzung',
      },
    },
  },
  heatingDistribution: {
    label: 'Angaben zu Ihrer Heizung',
    step: {
      label: 'Wärmeverteiler',
    },
    formFields: {
      distribution: {
        label: 'Welchen Wärmeverteiler haben Sie?',
        helper: 'lorem ipsum text',
        options: {
          radiator: {
            label: 'Heizkörper',
          },
          underfloorHeating: {
            label: 'Fußbodenheizung',
          },
        },
        title: 'Wärmeverteiler',
      },
    },
  },
  heatingConstructionYear: {
    label: 'Angaben zu Ihrer Heizung',
    step: {
      label: 'Baujahr',
    },
    formFields: {
      constructionYear: {
        customOption: 'Genaues Jahr',
        label: 'Baujahr oder letzte energetische Modernisierung?',
        options: {
          '1980': {
            label: 'von / vor 1980',
          },
          '2000': {
            label: 'um 2000',
          },
          today: {
            label: 'bis heute',
          },
        },
        title: 'Baujahr',
      },
    },
  },
  heatingBuildingType: {
    label: 'Angaben zu Ihrer Heizung',
    step: {
      label: 'Gebäudetyp',
    },
    formFields: {
      buildingType: {
        label: 'Um welche Gebäudeart handelt es sich?',
        options: {
          apartment: {
            label: 'Etagenwohnung',
          },
          multiFamily: {
            label: 'Mehrfamilienhaus',
          },
          other: {
            label: 'Sonstiges',
          },
          singleFamily: {
            label: 'Einfamilienhaus',
          },
        },
        title: 'Gebäudetyp',
      },
    },
  },
  assistantSubmit: {
    title: 'Ihre persönlichen Daten',
    emobility: {
      title: 'E-Mobilität',
      subtitle:
        'Gerne setzen wir uns für eine persönliche Beratung mit Ihnen in Verbindung. Füllen Sie dazu bitte folgendes Fomular aus.',
    },
    solar: {
      title: 'Solar',
      subtitle:
        'Gerne möchten wir Ihnen nun ihr persönliches Angebot zu Ihrer Auswahl senden. Füllen Sie dazu bitte noch folgendes Fomular aus.',
    },
    heating: {
      title: 'Wärme',
      subtitle:
        'Gerne möchten wir Ihnen nun ihr persönliches Angebot zu Ihrer Auswahl senden. Füllen Sie dazu bitte noch folgendes Fomular aus.',
    },
    step: {
      label: 'Kontaktformular',
    },
    formFields: {
      salutation: {
        label: 'Anrede',
        options: {
          w: { label: 'Frau' },
          m: { label: 'Herr' },
        },
      },
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      street: {
        label: 'Straße',
      },
      streetNumber: {
        label: 'Hausnr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Ort',
      },
      phone: {
        label: 'Telefonnummer',
      },
      email: {
        label: 'E-Mail',
      },
      note: {
        label: 'Kommentar',
      },
      buildingType: {
        label:
          'In/An welcher Art von Haus soll die Wallbox installiert werden?*',
        formattedLabel: 'Gebäudetyp',
        options: {
          single: { label: 'Einfamilienhaus' },
          'semi-detached': { label: 'Doppelhaushälfte' },
          townhouse: { label: 'Reihenhaus' },
          multi: { label: 'Mehrfamilienhaus / Wohnung / Andere' },
        },
      },
      solarInterest: {
        label: 'Ich habe außerdem Interessse an',
        formattedLabel: 'Intresse an',
        options: {
          storage: { label: 'einer Speicherbatterie' },
          wallbox: { label: 'einer Wallbox' },
        },
      },
      referral: {
        label: 'Wie sind Sie auf uns aufmerksam geworden?',
        formattedLabel: 'Aufmerksam geworden durch',
        options: {
          'personal-visit': { label: 'Persönlicher Besuch' },
          'promotion-internet': { label: 'Werbung im Internet' },
          'promotion-facebook': { label: 'Empfehlung durch Bekannte' },
          advertisement: { label: 'Anzeigen (z.B. Zeitung)' },
          recommendation: { label: 'Empfehlung durch Handwerksbetrieb' },
          'e-store': { label: 'E-Store' },
          fair: { label: 'Messen / Veranstaltungen' },
          canvasser: { label: 'Ansprache durch Akquisieur' },
          'invoice-inclosure': { label: 'Rechnungsbeileger' },
        },
      },
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
