import solarRoofShape, {
  SolarRoofShapeStepContext,
} from '~/steps/solar/roof-shape';
import solarRoofDirection, {
  SolarRoofDirectionStepContext,
} from '~/steps/solar/roof-direction';
import solarPowerUsage, {
  SolarPowerUsageStepContext,
} from '~/steps/solar/power-usage';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [solarRoofShape, solarRoofDirection, solarPowerUsage];

// Add the imported context interface for the steps
export interface SolarAssistantContext {
  solarRoofShape: SolarRoofShapeStepContext;
  solarRoofDirection: SolarRoofDirectionStepContext;
  solarPowerUsage: SolarPowerUsageStepContext;
}

export default steps;
