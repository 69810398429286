import { AssistantContext } from '~/steps/steps';
import { StepCategoryId } from '~/consts/assistant-steps';
import { StepDefinition } from '@/interfaces/step-definition';
import { getFormattedData } from './format';

// This function creates the comment for Shopware from context data
export const getRequestCommentFromContext = (
  steps: StepDefinition[],
  assistantContext: AssistantContext,
  categories: StepCategoryId[],
): string => {
  let commentString = '';

  steps = steps.filter(
    (step) =>
      categories &&
      categories.includes(step.categoryId as StepCategoryId) &&
      step.fields &&
      step.fields.length > 0,
  );

  const categoryId = categories[0] as StepCategoryId;
  const summary = getFormattedData({
    categoryId,
    assistantContext,
    steps,
    // target: OutputFormatterTypes.SUMMARY,
  });

  summary.map((category) => {
    if (category.categoryTitle) {
      commentString += `### ${category.categoryTitle}\n\n`;
    }
    category.steps.map((summaryStep, index) => {
      let stepHeadline = '\n';
      for (let i = 0; i < summaryStep.title.length; i++) {
        stepHeadline += '=';
      }
      commentString += `${summaryStep.title}${stepHeadline}\n`;
      summaryStep.fields.map((field) => {
        if (field.formattedLabel === false && field.formattedValue === false) {
          return;
        }
        if (
          field.formattedLabel !== false &&
          field.formattedValue !== false &&
          field.formattedValue !== '–'
        ) {
          commentString += `${field.formattedLabel}: `;
        }
        if (field.formattedValue !== false && field.formattedValue !== '–') {
          commentString += field.formattedValue;
        }
        commentString += '\n';
      });

      if (index < category.steps.length - 1) {
        commentString += '\n';
      }
    });
  });

  return commentString;
};
