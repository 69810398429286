import component from './SolarPowerUsage.vue';
import { StepCategoryId, StepId } from '~/consts/assistant-steps';
import {
  FormMultipleChoiceComponentConfig,
  FormMultipleChoiceValueType,
  FormTextFieldComponentConfig,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { AssistantStepContext } from '@/interfaces/context';
import { FormFieldType } from '@/consts/input-types';
import i18n from '@/plugins/i18n';
import { FormFieldGroupId } from '~/consts/assistant-form-field-groups';

export interface SolarPowerUsageStepContext extends AssistantStepContext {
  personCount: FormMultipleChoiceValueType;
  powerUsage: FormTextFieldValueType;
}

const config: StepDefinition = {
  component,
  hideSkipMessage: () => true,
  fields: [
    {
      component: {
        columns: 3,
        hideCheckbox: true,
        options: [
          {
            value: '1',
            image: {
              file: 'img/icon/people-1.svg',
              options: {
                height: 27,
                width: 25,
              },
            },
          },
          {
            value: '2',
            image: {
              file: 'img/icon/people-2.svg',
              options: {
                height: 27,
                width: 50,
              },
            },
          },
          {
            value: '3',
            image: {
              file: 'img/icon/people-3.svg',
              options: {
                height: 27,
                width: 75,
              },
            },
          },
          {
            value: '4',
            image: {
              file: 'img/icon/people-4.svg',
              options: {
                height: 27,
                width: 100,
              },
            },
          },
          {
            value: '5+',
            image: {
              file: 'img/icon/people-5.svg',
              options: {
                height: 27,
                width: 125,
              },
            },
          },
        ],
        singleAnswer: true,
      } as FormMultipleChoiceComponentConfig,
      name: 'personCount',
      required: false,
      type: FormFieldType.MultipleChoice,
      outputFormatter: () => ({
        formattedLabel: false,
        formattedValue: false,
      }),
    },
    {
      component: {
        type: 'number',
        min: 0,
        suffix: i18n.t('solarPowerUsage.formFields.powerUsage.suffix'),
      } as FormTextFieldComponentConfig,
      name: 'powerUsage',
      required: true,
      type: FormFieldType.TextField,
      groupId: FormFieldGroupId.POWER,
      outputFormatter: (data) => {
        if (data.formattedValue === '0' || data.formattedValue === '–') {
          return {
            formattedLabel: false,
            formattedValue: false,
          };
        }
        return {
          formattedLabel: i18n.t(
            'solarPowerUsage.formFields.powerUsage.formattedLabel',
          ) as string,
          formattedValue: data?.formattedValue + ' kWh',
        };
      },
    },
  ],
  id: StepId.SOLAR_POWER_USAGE,
  categoryId: StepCategoryId.SOLAR,
};

export default config;
