

































import { defineComponent, ref } from '@vue/composition-api';
import AssistantStep from '@/components/AssistantStep.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcImage from '@/components/primitives/GcImage.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import { StepId } from '~/consts/assistant-steps';

export default defineComponent({
  components: {
    AssistantStep,
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
    GcImage,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props, { emit }) => {
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);

    const onNext = () => {
      const interestValue = formData.value.interest;
      const target = interestValue.includes('buy')
        ? StepId.EMOBILITY_BUY
        : StepId.EMOBILITY_CHECK;

      emit('transition', {
        event: 'JUMP_TO_' + target.toUpperCase(),
      });
    };

    return {
      formData,
      formValid,
      onNext,
    };
  },
});
